import { GrowthLimitParameters } from "@backend/types"
import { Button, Stack, Switch, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../app/hooks"
import { NumberField } from "../../components/Fields"
import { selectCurrentForecastId } from "../apis/appSlice"
import { useGetGrowthLimitPercentagesQuery, useUpdateGrowthLimitPercentagesMutation } from "../apis/forecastApi"

// type MethodOptionsPropsType = {
//     valueLabel: string,
//     options: {
//         label: string,
//         value: string,
//         disabled?: boolean,
//     }[],
// }

// const MethodOptions = ({ valueLabel, options }:MethodOptionsPropsType) => {

//     return (
//         <Box sx={{ paddingBottom: '1rem' }}>
//         <FormControl>
//             <FormLabel id="demo-controlled-radio-buttons-group">{valueLabel}</FormLabel>
//             <RadioGroup
//                 row
//                 aria-labelledby="demo-controlled-radio-buttons-group"
//                 name="controlled-radio-buttons-group"
//                 value={null}
//                 onChange={() => {}}
//             >
//                 {
//                 options.map((option, key) => (
//                     <FormControlLabel key={key} value={option.value} disabled={option.disabled} control={<Radio />} label={option.label} />
//                 ))
//                 }
//             </RadioGroup>
//         </FormControl>
//         </Box>
//     )
// }

type GrowthLimitFieldPropsType = {
    valueLabel: string,
    value: number | undefined,
    onValueChange: (value:any) => void,
}
const GrowthLimitField = ({ valueLabel, value, onValueChange }: GrowthLimitFieldPropsType) => {

    const [ active, setActive ] = useState<boolean>(value !== undefined ? true : false)

    const activate = (active:boolean) => {
        setActive(active)
        onValueChange(active ? (value ? value : 0) : undefined)
    }

    return (
        <Grid2 spacing={2} width='100%' alignItems={'right'} container margin='auto'>
            <Grid2 xs={2} margin='auto'>
                <Switch checked={active} onChange={() => activate(!active)} />
            </Grid2>
            <Grid2 xs={8} margin='auto'>
                <Typography variant="h6">{valueLabel}</Typography>
            </Grid2>
            <Grid2 xs={2} display={"flex"} margin='auto'>
                <NumberField value={value} onChange={onValueChange} disabled={!active} />
                <Typography variant="caption">%</Typography>
            </Grid2>
        </Grid2>
    )
}

const GrowthLimitForm = () => {

    const { t } = useTranslation()

    const { id: forecastId } = useAppSelector(selectCurrentForecastId)!
    const { data } = useGetGrowthLimitPercentagesQuery({ forecastId })
    const [ update ] = useUpdateGrowthLimitPercentagesMutation()

    const [ growthLimits, setGrowthLimits ] = useState<GrowthLimitParameters | undefined>()

    const onValueChange = (key:string, value:number) => {
        if (growthLimits) {
            setGrowthLimits({
                ...growthLimits,
                [key]: value === undefined ? undefined : Number(value),
            })
        }
    }

    useEffect(() => {
        if (data) {
            setGrowthLimits(data)
        }
    }, [data])

    return (
        growthLimits ?
        <Stack>
            <GrowthLimitField
                onValueChange={(value) => onValueChange('maxIncreasePercentage', value)}
                valueLabel={(t('areaView.maxIncrease'))}
                value={growthLimits!.maxIncreasePercentage}
            />
            <GrowthLimitField
                onValueChange={(value) => onValueChange('maxDecreasePercentage', value)}
                valueLabel={(t('areaView.maxDecrease'))}
                value={growthLimits!.maxDecreasePercentage}
            />
            <Button variant="contained" onClick={() => { update({ forecastId, data: growthLimits }) }}>{t('general.apply')}</Button>
        </Stack>
        : null
    )
}

const AreaTotalsView = () => {

    const { t } = useTranslation()
    // const dispatch = useAppDispatch()

    // const options = [{
    //     label: t('forecastMethod.moving_average'),
    //     value: 'mean',
    // }, {
    //     label: t('forecastMethod.latest'),
    //     value: 'latest',
    // }, {
    //     label: t('forecastMethod.median'),
    //     value: 'median',
    // }]

    // const disabledProps = [{
    //     'label': t('forecastMethod.tkForecast'),
    //     'value': 'disabled',
    //     disabled: true,
    // }]

    return (
        <Stack>
            <h3>{t('areaView.forecastSettings')}</h3>
            <GrowthLimitForm />
            {/* <h3>{t('areaView.totalsView.title')}</h3>
            <hr />
            <MethodOptions valueLabel={t('projectionParameter.intraMunicipalMigrationIn')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.intraMunicipalMigrationOut')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.interMunicipalMigrationIn')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.interMunicipalMigrationOut')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.immigration')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.emigration')} options={options} />
            <MethodOptions valueLabel={t('projectionParameter.fertilityForecast')} options={disabledProps} />
            <MethodOptions valueLabel={t('projectionParameter.mortalityForecast')} options={disabledProps} />
            <Stack direction="row" spacing={2} width='100%' alignItems={'right'}>
                <Button variant="outlined" onClick={() => dispatch(closeDrawer())}>{t('general.cancel')}</Button>
                <Button variant="contained" onClick={() => { }}>{t('general.apply')}</Button>
            </Stack> */}
        </Stack>
    )
}

export default AreaTotalsView