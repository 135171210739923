import * as dfd from 'danfojs';

type WideRow = {
    year: number;
    areaId: string;
    areaName: string;
    [key: string]: number | string;
}

export type TransformedRowInput = {
    d: any,
    areaNames: Record<string, string>,
    areas: string[],
    ages: { from: number, to: number },
    ageGroups: { from: number; to: number }[],
    areaAggregation: number,
}

export const serializeDataframe = (data: dfd.DataFrame): any => {
    return dfd.toJSON(data)
}

export const deserializeDataframe = (data: any): dfd.DataFrame => {

    const dataframe = new dfd.DataFrame(data)
    dataframe.setIndex({ index: data.map(({ year, area }: any) => `${year}_${area}`), inplace: true })

    return dataframe
}

const aggregateNarrowRows = (narrowRow: NarrowRow[], areas: string[], areaNames: Record<string, string>): NarrowRow[] => {

    const years = [...new Set(narrowRow.map((row) => row.year))]
    const ageGroups = [...new Set(narrowRow.map((row) => row.ageGroup))]

    let unknowns: NarrowRow[] = []
    let rest: NarrowRow[] = narrowRow

    if (areas.length > 1) {
        unknowns = narrowRow.filter((row) => row.areaId.endsWith('999999'))
        rest = narrowRow.filter((row) => !row.areaId.endsWith('999999'))
    }

    const aggregated: NarrowRow[] =
        years.flatMap(
            year => {
                return ageGroups.flatMap(aGroup => areas.map((areaId) => {
                    return rest.reduce((majorArea, row, idx) => {
                        if (row.areaId.startsWith(areaId) && row.year === year && row.ageGroup === aGroup) {

                            const { value } = row

                            return {
                                ...majorArea,
                                year,
                                areaId,
                                areaName: areaNames[areaId],
                                ageGroup: row.ageGroup,
                                value: (majorArea.value as any || 0) + Number(value)
                            }
                        }

                        return majorArea

                    }, {} as NarrowRow)
                }))
            }) as any

    return aggregated.concat(unknowns).sort((a, b) => (a.year - b.year) || a.ageGroup.localeCompare(b.ageGroup))
}

const aggregateWideRows = (wideRow: WideRow[], areas: string[], areaNames: Record<string, string>): WideRow[] => {

    const years = [...new Set(wideRow.map((row) => row.year))]
    const aggregated: WideRow[] = years.flatMap(year => {

        let unknown: WideRow[] = []
        if (areas.length > 1) {
            const unknownIndex = wideRow.findIndex((row) => row.year === year && row.areaId.endsWith('999999'))
            unknown = unknownIndex === -1 ? [] : wideRow.splice(unknownIndex, 1)
        }

        return areas.map((areaId) => {
            return wideRow.reduce((majorArea, row) => {

                if (row.areaId.startsWith(areaId) && row.year === year) {

                    const { year: _, areaId: __, areaName: ___, ...rest } = row

                    return {
                        ...majorArea,
                        year,
                        areaId,
                        areaName: areaNames[areaId],
                        ...(Object.entries(rest).reduce((prev, [age, count]) => ({
                            ...prev,
                            [age]: (majorArea[age] as any || 0) + Number(count)
                        }), {} as any))
                    }
                }

                return majorArea

            }, {} as WideRow)

        }).concat(unknown)
    }
    ) as any

    return aggregated
}

export const asWideData = ({ d, areaNames, areas, ages, ageGroups, areaAggregation }: TransformedRowInput): WideRow[] => {

    const data = deserializeDataframe(d)

    const years = data.column('year').unique().values;
    const areaIds = data.column('area').unique().values;
    const areaIdsString = areaIds.map((areaId) => areaId.toString());
    const filteredAreaIds = areas ? areaIdsString.filter((areaId) => areas.includes(areaId.toString())) : areaIds;
    const minAge = ages ? ages.from : 0;
    const maxAge = ages ? ages.to : 99;

    const rows: WideRow[] = [];

    years.forEach((year: any) => {
        filteredAreaIds.forEach((areaId: any) => {
            const areaName = areaNames[areaId];
            const row: WideRow = { year: year, areaId: areaId, areaName: areaName };

            const indexValue = year + "_" + areaId
            ageGroups.forEach((ageGroup) => {
                const ageGroupLabel = labelFor(ageGroup, ' ');
                const femaleCohorts = cohortsFor(ageGroup, 'f', minAge, maxAge);
                const maleCohorts = cohortsFor(ageGroup, 'm', minAge, maxAge);

                if (femaleCohorts.length !== 0) { // assuming same length for maleCohorts
                    const females = selectRowsAndColumns(data, [indexValue], femaleCohorts).sum().sum();
                    const males = selectRowsAndColumns(data, [indexValue], maleCohorts).sum().sum();

                    row[ageGroupLabel] = females + males
                }
            })

            rows.push(row);
        });
    });

    if (areaAggregation === 1) {
        const majorAreas = [...new Set(areas.map((area) => area.slice(0, 3)))]
        return aggregateWideRows(rows, majorAreas, areaNames)
    } else if (areaAggregation === 2) {
        const majorAreas = [...new Set(areas.map((area) => area.slice(0, 4)))]
        return aggregateWideRows(rows, majorAreas, areaNames)
    } else {
        return rows
    }
}

type NarrowRow = {
    year: number;
    areaId: string;
    areaName: string;
    ageGroup: string;
    value: number | string;
}
export const asTallData = ({ d, areaNames, areas, ages, ageGroups, areaAggregation }: TransformedRowInput): NarrowRow[] => {

    const data = deserializeDataframe(d)

    const years = data.column('year').unique().values;
    const areaIds = data.column('area').unique().values;
    const areaIdsString = areaIds.map((areaId) => areaId.toString());
    const filteredAreaIds = areas ? areaIdsString.filter((areaId) => areas.includes(areaId.toString())) : areaIds;
    const minAge = ages ? ages.from : 0;
    const maxAge = ages ? ages.to : 99;

    const rows: NarrowRow[] = [];

    years.forEach((year: any) => {
        filteredAreaIds.forEach((areaId: any) => {
            const indexValue = year + "_" + areaId
            ageGroups.forEach((ageGroup) => {
                const areaName = areaNames[areaId];
                const ageGroupLabel = labelFor(ageGroup, ' ');
                const femaleCohorts = cohortsFor(ageGroup, 'f', minAge, maxAge);
                const maleCohorts = cohortsFor(ageGroup, 'm', minAge, maxAge);

                if (femaleCohorts.length !== 0) { // assuming same length for maleCohorts
                    const females = selectRowsAndColumns(data, [indexValue], femaleCohorts).sum().sum();
                    const males = selectRowsAndColumns(data, [indexValue], maleCohorts).sum().sum();

                    const row: NarrowRow = { year: year, areaId: areaId, areaName: areaName, ageGroup: ageGroupLabel, value: females + males };
                    rows.push(row);
                }
            })
        });
    });

    if (areaAggregation === 1) {
        const majorAreas = [...new Set(areas.map((area) => area.slice(0, 3)))]
        return aggregateNarrowRows(rows, majorAreas, areaNames)
    } else if (areaAggregation === 2) {
        const majorAreas = [...new Set(areas.map((area) => area.slice(0, 4)))]
        return aggregateNarrowRows(rows, majorAreas, areaNames)
    } else {
        return rows
    }
}

const isWideRow = (row: WideRow | NarrowRow): row is WideRow => {
    return !("ageGroup" in row); // narrow-style rows have ageGroup property
}

export const toCsv = (data: WideRow[] | NarrowRow[]): string => {
    // Check if data is empty
    if (!data || !data.length) return ''; // applies to tall data (i.e. narrow rows)
    if (isWideRow(data[0]) && Object.keys(data[0]).length === 3) return ''; // no value columns, applies to wide data

    const delimiter = ';';
    const decimalSeparator = ',';
    const newline = '\n';

    // Check the type of data and set index and value columns accordingly
    const isWide = isWideRow(data[0]);
    const indexColumns = isWide ? ["year", "areaId", "areaName"] : ["year", "areaId", "areaName", "ageGroup"];
    const valueColumns = isWide
        ? Object.keys(data[0]).filter(key => !indexColumns.includes(key))
        : ["value"];

    // Generate CSV header
    const header = [...indexColumns, ...valueColumns].join(delimiter);

    // Format each row
    const rows = data.map(row => {
        return [
            // process index columns -> escape possible existing double quotes and quote
            ...indexColumns.map(col => escapeAndQuote(row[col as keyof typeof row])),

            // process value columns -> format numbers 
            ...valueColumns.map(col => {
                const value = row[col as keyof typeof row];
                if (typeof value === 'number') {
                    return value.toFixed(2).replace('.', decimalSeparator);
                }
                return isNaN(Number(value)) ? "" : Number(value).toFixed(2).replace('.', decimalSeparator);
            })
        ].join(delimiter);
    });

    return [header, ...rows].join(newline);
};

const escapeAndQuote = (value: string | number): string => {
    const stringValue = String(value);
    // Escape double quotes by replacing each " with ""
    const escapedValue = stringValue.replace(/"/g, '""');
    // Wrap the result in double quotes
    return `"${escapedValue}"`;
};

const labelFor = (ageGroup: { from: number; to: number }, prefix: string): string => {
    if (ageGroup.from === ageGroup.to) return `${prefix}${ageGroup.from}`;
    else return `${prefix}${ageGroup.from}-${ageGroup.to}`;
};

const cohortsFor = (ageGroup: { from: number; to: number }, prefix: 'm' | 'f', minAge: number, maxAge: number): string[] => {
    if (ageGroup.from < minAge || (ageGroup.to > maxAge && maxAge !== 99)) return [];

    const cohorts: string[] = [];
    for (let age = ageGroup.from; age <= ageGroup.to; age++) {
        cohorts.push(`${prefix}${age}`);
    }
    return cohorts;
};

const selectRowsAndColumns = (dataframe: dfd.DataFrame, rows: string[], columns: string[]): dfd.DataFrame => {
    const selectMask = dataframe.index.map(index => rows.includes(index.toString()));
    const selected = dataframe.loc({ rows: selectMask, columns: columns }); // true or false for each index value, depending on if it is found from the rows parameter
    return selected;
};

